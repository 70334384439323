@import './src/scss/config/_variables.scss';


.navigation-bar {
  background: rgba(0, 0, 0, .8);
  height: $navigation-height;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  .brand-logo {
    height: 68px;
  }
}

.navigation-menu {
  ul {
    display: none;
  }
}

.navigation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $navigation-height;
  padding: 10px;
}

.hamburger {
  height: 35px;
  width: 40px;
  background: transparent;
  border: 0;
  border-top: 5px solid #fff;
  position: relative;
  margin: 10px;
  transition: 0.3s transform ease-in-out;
}

.hamburger::before, .hamburger::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 5px solid #fff;
  transform: translateY(10px)
}

.hamburger::after {
  transform: translateY(25px);
  transition: 0.3s transform ease-in-out;
}

.hamburger-active {
  transform: rotate(45deg) translateY(5px);
  border: none;
  outline: none;
}

.hamburger-active::after {
  transform: rotate(-90deg) translateX(-10px);
}

.side-menu {
  background: #fff;
  border-left: 1px solid $color-main;
  border-bottom: 1px solid $color-main;
  border-bottom-left-radius: 10px;
  box-shadow: 8px 4px 5px rgba(0, 0, 0, 1);
  height: 75%;
  width: 80%;
  position: fixed;
  top: 130px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  color: #000;
  transform: translateX(100%);
  transition: 0.3s transform ease;
  z-index: 100;

  ul {
    margin: 0 15px;
    width: 100%;
  }

  li {
    background: white;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;

  }

  a {
    padding: 10px;
    text-decoration: none;
    color: $color-second;
    font-size: 2rem;
    width: 100%;
    border-left: 1px solid $color-second;
  }

  &.show {
    transform: translateX(0);
  }

  .active {
    border-bottom: 3px solid $color-second;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 50;
  background: rgba(0, 0, 0, 0.7);
}

@media screen and (min-width: 801px) {
  .navigation-container {
    padding: 0;
  }
  .navigation-menu {
    height: 100%;

    ul {
      display: flex;
      height: 100%;

      li {
        margin: 0 10px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 10px;
        border-bottom: 3px solid rgba(0, 0, 0, 0);
        color: #fff;
        text-decoration: none;
        font-weight: 600;

        &:hover {
          border-bottom: 3px solid $color-contrast;
          color: $color-contrast;
          font-weight: 600;
          letter-spacing: 0;
        }
      }
    }
  }
  .hamburger {
    display: none;
  }
}