@import "src/scss/config/variables";

footer {
  background: $color-main;
  width: 100%;
  height: 100%;
  color: #fff;

  .overfoot {
    background: $color-second;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    letter-spacing: 5px;
  }

  .overfoot-lg {
    display: none;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .foot-nav-items {
    padding: 5px 10px 5px 10px;

    li {
      margin-top: 5px;
      display: flex;
    }

    a {
      font-family: Roboto, sans-serif;
      font-weight: 300;
      font-size: 1.5rem;
      width: 90%;
      padding: 5px 0;

      &:hover {
        color: $color-contrast;
      }
    }
  }

  .foot-brand-name {
    display: none;
  }

  .foot-contact {
    padding: 0 10px;

    p {
      margin-top: 5px;
    }

    div {
      margin-top: 15px;
    }
    a:hover {
      color: $color-contrast;
    }
  }

  .copyright {
    height: 40px;
    width: 100%;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-second;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .active {
    border-bottom: 1px solid $color-second;
  }
}

@media screen and (min-width: 800px) {
  footer {
    overflow: hidden;

    .overfoot {
      display: none;
    }

    .overfoot-lg {
      display: flex;
      height: 150px;
      width: 100%;
      border-bottom: 100px solid $color-second;
      border-left: 1900px solid #fff;
      margin-top: -50px;
    }
    .overfoot-bar-lg {
      width: 100%;
      background: $color-second;
      height: 50px;
      display: block;
    }
  }

  .container {
    .row {
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      .foot-nav-items {
        grid-column: 1 / span 3;

        a {
          font-size: 18px;
        }
      }

      .foot-brand-name {
        display: block;
        grid-column: 4 / span 5;
        margin: auto;
        font-weight: bold;
        text-decoration: underline;
        letter-spacing: 5px;

        a {
          font-size: 18px;
        }
      }

      .foot-contact {
        grid-column: span 4/ 13;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .container {
    .row {
      .foot-brand-name {
        a {
          font-size: 26px;
        }
      }
    }
  }
}
