@import "src/scss/config/variables";
@import "src/scss/config/mixins";

.lazy-container {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5px;
}

@keyframes stretch {
  0%,
  70%,
  100% {
    height: 4rem;
    box-shadow: 0 0;
  }
  30% {
    box-shadow: 0 -2rem;
    height: 5rem
  }
}

.loader, .loader::after, .loader::before {
  width: 1rem;
  height: 4rem;
  background: $color-main;
  border-radius: 5px;
  animation: stretch 1s infinite ease;
}

.loader {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 1.5rem;
    animation-delay: 0.16s;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 1.5rem;
    animation-delay: -0.16s;
  }
}