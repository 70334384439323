$desktop: 1200px;

$navigation-height: 130px;

$color-main: #2B2E38;
$color-second: #6F6D78;
$color-contrast: #ce6300;
$color-font-light: #ffffff;
$color-recycle: #316d42;

$shadow-icons: 3px 3px 4px rgba(0, 0, 0, .25);